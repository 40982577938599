import React from 'react';

const Skeleton = () => {
     return (
          <div>
               
          </div>
     );
};

export default Skeleton;